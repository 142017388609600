<template>
    <div 
        class="w-full lg:mx-3 text-left">
      
        <h5 class="font-semibold text-base md:text-xl mb-1"> {{ $t('karir.title1') }}</h5>
        <div v-html="karirLang" class="mt-1 text-sm lg:text-base text-justify"></div>
        
        <div class="mt-2 flex justify-between flex-wrap">
            <div class="cursor-pointer text-grays-50 flex w-full md:w-47% lg:w-31% mt-8 h-32" 
                v-for="(item, i) in company" :key="i"
                @click="handleView(item.id, item.job_available, item.redirect_link)"
               
            >
                <div class="bg-secondary  w-5/6 p-4 rounded-tl-sm rounded-bl-sm flex flex-col justify-center">
                    <div class="font-bold text-xl mb-1">{{item.name}}</div>
                    <!-- <span class="text-xs">{{item.job_available}} {{ $t('karir.avalible') }}</span> -->
                </div>
                <div class=" bg-primary text-grays-50 w-20  rounded-tr-sm rounded-br-sm grid place-items-center">
                    <img src="@/assets/img/right-arrow-white.svg" alt="" class="w-6">
                </div>
            </div>

            <div class="cursor-pointer text-grays-50 flex w-full md:w-47% lg:w-31% mt-8 h-32 invisible"
            >
                <div class="bg-secondary  w-5/6 p-4 rounded-tl-sm rounded-bl-sm flex flex-col justify-center">
                    <div class="font-bold text-xl mb-1"></div>
                    <span class="text-xs"></span>
                </div>
                <div class=" bg-primary text-grays-50 w-20  rounded-tr-sm rounded-br-sm grid place-items-center">
                    <img src="@/assets/img/right-arrow-white.svg" alt="" class="w-6">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
   
    computed: {
        ...mapState({
            company: state => state.karir.company ? state.karir.company.data :'',
			listLoker: state => state.karir.listLoker ? state.karir.listLoker.data :'',
			lang: state => state.home.lang ? state.home.lang :'id',
            data: state => state.home.home ? state.home.home.data :'',
        }),

        karirLang(){
			let karirTab_id = {}
			let karirTab_eng= {}

			if (this.lang == 'id'){
				Object.assign(karirTab_id, 
					{career: this.data.career},
				);
				return karirTab_id.career
			}else{
				Object.assign(karirTab_eng, 
					{career: this.data.career_eng}
				);
				return karirTab_eng.career
			}
		}

        
    },
    methods: {
        handleView(id, total_career, redirect_link){
            // if(total_career === 0 ) return false
            // this.$store.commit('karir/SET_COMPANY_ID', id)
            window.open(redirect_link, '_blank');
        }
    },
}
</script>